import { Image } from 'cloudinary-react';
import cx from 'lib/cx';
import PropTypes from 'prop-types';

import config from 'config';
import TextWrapper from './CampaignPageShopCardText';

const CampaignPageShopCard = ({
  name,
  description,
  amount,
  image,
  qtyRemaining,
  clickHandler,
  isSmall,
  isDescriptionRequired,
  variants,
  isShopFeatureActive,
  isSingleProduct,
}) => {
  const isSoldOut =
    qtyRemaining === 0 &&
    (variants || []).reduce((acc, { qtyRemaining }) => acc + qtyRemaining, 0) === 0;

  return (
    <div className="@container w-full">
      <div className="flex flex-col w-full h-full @2xl:flex-row items-stretch bg-white shadow-lg border border-gray-300 rounded-lg overflow-hidden">
        <ImgWrapper
          value={image}
          isSingleProduct={isSingleProduct}
          isSmall={isSmall}
          isSoldOut={isSoldOut && isShopFeatureActive}
        />

        <TextWrapper
          name={name}
          description={description}
          amount={amount}
          clickHandler={clickHandler}
          isSmall={isSmall}
          isDescriptionRequired={isDescriptionRequired}
          isSoldOut={isSoldOut}
          isShopFeatureActive={isShopFeatureActive}
        />
      </div>
    </div>
  );
};

const ImgWrapper = ({ value, isSingleProduct, isSmall, isSoldOut }) => {
  return (
    <div
      className={cx('@container w-full h-full md:h-[28rem]', {
        'md:h-[20rem]': !isSingleProduct,
        'md:h-[14rem]': isSmall,
      })}
    >
      <figure
        className={cx('relative overflow-hidden w-full h-full', {
          'opacity-50': isSoldOut,
        })}
      >
        <Image
          publicId={value ?? config('/defaultAuctionItemImage')}
          width={200}
          alt="shop-product-img"
          className="w-full h-full object-cover"
        />
      </figure>
    </div>
  );
};
CampaignPageShopCard.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  qtyRemaining: PropTypes.number.isRequired,
  clickHandler: PropTypes.func.isRequired,
  description: PropTypes.string,
  isSmall: PropTypes.bool,
  isSingleProduct: PropTypes.bool,
  isDescriptionRequired: PropTypes.bool,
  variants: PropTypes.array,
  isShopFeatureActive: PropTypes.bool,
};

CampaignPageShopCard.defaultProps = {
  isSmall: false,
  variants: [],
  description: '',
  isShopFeatureActive: true,
};

export default CampaignPageShopCard;
