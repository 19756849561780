import PropTypes from 'prop-types';
import cx from 'lib/cx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/common/Button';
import Pill from 'components/common/Pill';
import { formatCurrency } from '../../lib/formatters';
import { serializeToJSX } from '@/lib/serializeTiptap';
import { useResizeObserverRef } from 'rooks';
import { useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { faPlusLarge } from '@fortawesome/pro-solid-svg-icons';

const CampaignPageShopCardText = ({
  name,
  description,
  amount,
  clickHandler,
  isSmall,
  isDescriptionRequired,
  isSoldOut,
  isShopFeatureActive,
}) => {
  return (
    <div className="@container flex flex-col justify-center gap-1 w-full px-4 py-6 @md:px-8 ">
      <h2 className="@xs:text-lg @sm:text-xl @md:text-2xl font-medium">{name}</h2>
      <div className="flex items-center justify-between">
        <p
          className={cx('text-xl @md:text-2xl ', {
            'text-gray-600': !isDescriptionRequired,
          })}
        >
          {formatCurrency(amount)}
        </p>
        {isSmall && isShopFeatureActive && (
          <ButtonType type="small" clickHandler={clickHandler} isSoldOut={isSoldOut} />
        )}
      </div>

      {isDescriptionRequired && <Description value={description} />}
      {!isSmall && isShopFeatureActive && (
        <ButtonType clickHandler={clickHandler} isSoldOut={isSoldOut} />
      )}
    </div>
  );
};

const ButtonType = ({ isSoldOut, type, clickHandler }) => {
  if (isSoldOut) {
    return (
      <div>
        <Pill
          padding="sm"
          color="red"
          className={cx('font-medium ', {
            'mt-6': type !== 'small',
          })}
        >
          Sold Out
        </Pill>
      </div>
    );
  }

  if (type === 'small') {
    return (
      <Button
        as="button"
        type="submit"
        color="primary"
        padding="sm-even"
        className="font-medium rounded-full shrink-0"
        onClick={clickHandler}
      >
        <FontAwesomeIcon icon={faPlusLarge} size="1x" />
      </Button>
    );
  }
  return (
    <Button
      as="button"
      type="submit"
      color="primary"
      padding="sm"
      className="font-medium w-full rounded-full mt-8 md:mt-10"
      onClick={clickHandler}
    >
      Purchase
    </Button>
  );
};

const Description = ({ value }) => {
  const [isDescriptionExpandable, setIsDescriptionExpandable] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const [descriptionElem] = useResizeObserverRef((e) =>
    setIsDescriptionExpandable(e[0].target.scrollHeight > e[0].target.clientHeight)
  );

  return (
    <div className="mt-2 text-gray-600">
      <div
        ref={descriptionElem}
        className={cx('leading-relaxed  @xs:text-base @sm:text-lg @md:text-xl rich-text', {
          'line-clamp-4': !isDescriptionExpanded,
        })}
      >
        {serializeToJSX(value)}
      </div>

      {isDescriptionExpandable || isDescriptionExpanded ? (
        <p className="mt-2">
          <Button
            as="button"
            type="button"
            color="gray-300"
            padding="sm"
            className="mt-4 uppercase font-medium text-xs"
            onClick={() => setIsDescriptionExpanded((prev) => !prev)}
            outline
            radius="full"
          >
            {isDescriptionExpanded ? 'Read less' : 'Read more'}
            <FontAwesomeIcon
              icon={!isDescriptionExpanded ? faChevronDown : faChevronUp}
              className="ml-2"
            />
          </Button>
        </p>
      ) : null}
    </div>
  );
};

CampaignPageShopCardText.propTypes = {
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  clickHandler: PropTypes.func.isRequired,
  description: PropTypes.string,
  isSmall: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  isShopFeatureActive: PropTypes.bool,
};

CampaignPageShopCardText.defaultProps = {
  isSmall: false,
  isSoldOut: false,
  description: '',
  isShopFeatureActive: true,
};

export default CampaignPageShopCardText;
