import PropTypes from 'prop-types';
import cx from 'lib/cx';

import Button from 'components/common/Button';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { motion } from 'framer-motion';
import useBreakpoint from 'hooks/useBreakpoint';
import CampaignPageEventAddress from './CampaignPageEventAddress';

const CampaignPageEventInfo = ({ eventWidget, eventDetails }) => {
  return (
    <div className="w-full ">
      <h2 className={'text-2xl lg:text-4xl font-medium leading-tight'}>{eventWidget.title}</h2>
      <Description value={eventWidget.config?.info} />

      {eventWidget.config?.image && (
        <div className="mt-8">
          <CampaignPageEventAddress eventDetails={eventDetails} />
        </div>
      )}
    </div>
  );
};

const Description = ({ value }) => {
  const el = useRef(null);
  const [state, setState] = useState('default');

  const mobile = !useBreakpoint('lg');
  const previewHeight = useMemo(() => (mobile ? 100 : 70), [mobile]);

  useEffect(() => {
    const canCollapse = (el.current?.offsetHeight ?? 0) > previewHeight + 30;
    if (canCollapse && state === 'default') {
      setState('collapsed');
    } else if (!canCollapse && state !== 'default') {
      setState('default');
    }
  }, [el, previewHeight, state]);

  return (
    <div className="mt-2 text-gray-600">
      <motion.div
        animate={{ height: state === 'collapsed' ? previewHeight : 'auto' }}
        className={cx('relative overflow-hidden')}
        transition={{ duration: 0.25 }}
      >
        <p ref={el}> {value}</p>

        <motion.div
          className={cx('absolute bottom-0 inset-x-0 h-24')}
          animate={{ opacity: state === 'collapsed' ? 1 : 0 }}
        />
      </motion.div>
      {state !== 'default' && (
        <p className="mt-2">
          <Button
            as="button"
            type="button"
            color="gray-300"
            padding="sm"
            className="mt-1 uppercase font-medium text-xs"
            onClick={() => setState(state === 'expanded' ? 'collapsed' : 'expanded')}
            outline
            radius="full"
          >
            {state === 'collapsed' ? 'Read more' : 'Read less'}
            <FontAwesomeIcon
              icon={state === 'collapsed' ? faChevronDown : faChevronUp}
              className="ml-2"
            />
          </Button>
        </p>
      )}
    </div>
  );
};

CampaignPageEventInfo.propTypes = {
  eventWidget: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
};

CampaignPageEventInfo.defaultProps = {
  eventWidget: {},
  eventDetails: {},
};

export default CampaignPageEventInfo;
