import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { motion } from 'framer-motion';
import { scroller } from 'react-scroll';
import cx from 'classnames';

import { useCampaignPage } from 'context/CampaignPage';
import NotFoundPage from 'pages/404';
import HorizontalScrollContainer from 'components/common/HorizontalScrollContainer';
import CampaignPageSeo from 'components/campaign-page/CampaignPageSeo';
import CampaignPageHero from 'components/campaign-page/CampaignPageHero';
import CampaignPageProgressDial from 'components/campaign-page/CampaignPageProgressDial';
import CampaignPageStory from 'components/campaign-page/CampaignPageStory';
import CampaignPageLeaderboards from 'components/campaign-page/CampaignPageLeaderboards';
import CampaignPageGroupLeaderboards from 'components/campaign-page/CampaignPageGroupLeaderboards';
import CampaignPageCustomBlock from 'components/campaign-page/CampaignPageCustomBlock';
import CampaignPageCommunityActivity from 'components/campaign-page/CampaignPageCommunityActivity';
import CampaignPageImpactStories from 'components/campaign-page/CampaignPageImpactStories';
import CampaignPageFeaturedUpdates from 'components/campaign-page/CampaignPageFeaturedUpdates';
import CampaignPageAuctionPreview from 'components/campaign-page/CampaignPageAuctionPreview';
import CampaignPageLogoGrid from 'components/campaign-page/CampaignPageLogoGrid';
import CampaignPageSponsors from 'components/campaign-page/CampaignPageSponsors';
import CampaignPageSponsorPackages from 'components/campaign-page/CampaignPageSponsorPackages';
import CampaignPageEvent from './CampaignPageEvent';
import CampaignPageRaffle from './CampaignPageRaffle';
import CampaignPageShop from './CampaignPageShop';

const GET_CAMPAIGN = gql`
  query GetCampaignPageCampaign($id: String!) {
    findCampaigns(id: $id) {
      id
      heroStyle
      monetaryGoal
      campaignShowGoalDial
      stats {
        estimatedAmountRaised
      }

      activeFeatures {
        giving
        join
      }

      pages(order: "order") {
        id
        name
        slug

        widgets(order: "order") {
          id
          type
          title
          order
          config
        }
      }
    }
  }
`;

const CampaignPage = ({ page }) => {
  const { campaignId, onDonateToCampaign, onJoinCampaign } = useCampaignPage();
  const [activePage, setActivePage] = useState(page);

  const { data } = useQuery(GET_CAMPAIGN, { variables: { id: campaignId } });
  const campaign = useMemo(() => data?.findCampaigns?.[0], [data]);
  const activeFeatures = useMemo(() => campaign?.activeFeatures, [campaign]);
  const pageSections = useMemo(
    () => campaign?.pages.find((x) => x.slug === activePage)?.widgets ?? [],
    [campaign, activePage]
  );
  const isMultiPage = campaign?.pages.length > 1;
  const hasFloatingStory = !isMultiPage && campaign?.heroStyle === 'fade';

  if (!campaign) return null;
  if (page && !campaign.pages.some((x) => x.slug === page)) return <NotFoundPage />;

  return (
    <>
      <CampaignPageSeo />
      <CampaignPageHero />

      {isMultiPage && (
        <section name="pageNav" className="border-t border-b border-gray-300 bg-white">
          <div className="container max-w-7xl">
            <HorizontalScrollContainer>
              <nav className="flex gap-x-6 pt-4">
                {campaign.pages.map(({ slug, title, name }) => {
                  const isActive = activePage === slug;

                  let pageUrl = `/c/${campaignId}`;
                  if (slug !== '/') pageUrl += `/pages/${slug}`;

                  return (
                    <button
                      key={slug}
                      type="button"
                      className={cx(
                        'font-medium transition-colors duration-200 border-b-3 py-4 whitespace-nowrap',
                        {
                          'text-gray-600 border-transparent hover:text-gray-800 hover:border-gray-400':
                            !isActive,
                          'text-gray-800 border-gray-800': isActive,
                        }
                      )}
                      onClick={() => {
                        setActivePage(slug);
                        window.history.pushState({}, title, pageUrl);
                        scroller.scrollTo('pageNav', {
                          duration: 1000,
                          delay: 100,
                          smooth: true,
                          offset: -79,
                        });
                      }}
                    >
                      {name === 'index' ? 'Home' : name}
                    </button>
                  );
                })}
              </nav>
            </HorizontalScrollContainer>
          </div>
        </section>
      )}

      {activePage === '/' && (
        <div
          className={cx('relative z-10 bg-white py-8 border-b border-gray-300', {
            'lg:py-16': !hasFloatingStory,
            'mt-8 mb-16 border-t lg:bg-transparent lg:-mt-40 lg:py-0 lg:border-t-0 lg:border-b-0':
              hasFloatingStory,
          })}
        >
          <div
            className={cx('container mx-auto', {
              'max-w-5xl': !hasFloatingStory,
              'max-w-6xl lg:bg-white lg:shadow-2xl lg:p-12 lg:rounded-xl': hasFloatingStory,
            })}
          >
            <div className="flex items-start gap-x-16">
              {campaign.campaignShowGoalDial && campaign.monetaryGoal && (
                <div className="shrink-0 w-80 hidden lg:block">
                  <CampaignPageProgressDial
                    goal={campaign.monetaryGoal}
                    raised={campaign.stats.estimatedAmountRaised}
                  />
                </div>
              )}
              <div className="flex-1">
                <CampaignPageStory allowCollapse />
              </div>
            </div>
          </div>
        </div>
      )}

      <motion.section key={activePage} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {pageSections.map((section) => {
          switch (section.type) {
            case 'customBlock': {
              return <CampaignPageCustomBlock key={section.id} id={section.id} />;
            }

            case 'leaderboards': {
              return null;
            }

            case 'donations': {
              return <CampaignPageCommunityActivity title={section.title} key="donations" />;
            }

            case 'impactStories': {
              return (
                <CampaignPageImpactStories
                  key={section.id}
                  id={section.id}
                  onJoin={activeFeatures.join ? onJoinCampaign : null}
                  onDonate={activeFeatures.giving ? onDonateToCampaign : null}
                />
              );
            }

            case 'leaderboard': {
              return <CampaignPageLeaderboards key={section.id} id={section.id} />;
            }

            case 'groupLeaderboard': {
              return (
                <CampaignPageGroupLeaderboards
                  key={section.id}
                  id={section.id}
                  limit={5}
                  showViewAll
                />
              );
            }

            case 'communityActivity': {
              return <CampaignPageFeaturedUpdates key="communityActivity" />;
            }

            case 'auctionPreview': {
              return <CampaignPageAuctionPreview title={section.title} key="auctionPreview" />;
            }

            case 'sponsors': {
              return <CampaignPageSponsors key={section.id} id={section.id} />;
            }

            case 'sponsorPackages': {
              return <CampaignPageSponsorPackages key={section.id} id={section.id} />;
            }

            case 'logoGrid': {
              return <CampaignPageLogoGrid key={section.id} id={section.id} />;
            }

            case 'eventDetails': {
              return <CampaignPageEvent key={section.id} id={section.id} />;
            }

            case 'raffle': {
              return <CampaignPageRaffle key={section.id} id={section.id} />;
            }

            case 'shop': {
              return <CampaignPageShop key={section.id} id={section.id} />;
            }

            default: {
              throw new Error(`Unknown section type: "${section.type}"`);
            }
          }
        })}
      </motion.section>
    </>
  );
};

CampaignPage.propTypes = {
  page: PropTypes.string,
};

CampaignPage.defaultProps = {
  page: '/',
};

export default CampaignPage;
