import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import SwipeableViews from 'react-swipeable-views';
import { motion } from 'framer-motion';
import cx from 'classnames';

import useBreakpoint from 'hooks/useBreakpoint';
import CampaignPageImpactStoryCard from './CampaignPageImpactStoryCard';

const GET_SECTION = gql`
  query GetImpactStories($id: String!) {
    findCampaignWidgets(id: $id) {
      id
      title
      config
    }
  }
`;

const CampaignPageImpactStories = ({ id, onJoin, onDonate, className }) => {
  const { data } = useQuery(GET_SECTION, { variables: { id } });

  const section = useMemo(() => data?.findCampaignWidgets?.[0], [data]);

  const isMobile = !useBreakpoint('lg');
  const [currentSlide, setCurrentSlide] = useState(0);

  if (!section || section?.config?.stories.length < 1) return null;
  return (
    <section
      className={cx('py-16 overflow-hidden', className)}
      style={{
        background: section?.config.backgroundColor,
      }}
    >
      <div className="container max-w-7xl">
        {section.title && (
          <h2
            className="text-3xl lg:text-4xl font-medium leading-tight mb-8 text-center"
            style={{ color: section?.config.titleColor }}
          >
            {section.title}
          </h2>
        )}

        {isMobile ? (
          <SwipeableViews
            className="max-w-xs md:max-w-sm mx-auto"
            style={{ overflowX: 'visible' }}
            slideStyle={{ overflow: 'visible' }}
            onSwitching={(index, type) => {
              if (type === 'end') setCurrentSlide(index);
            }}
            enableMouseEvents
          >
            {section?.config.stories.map((story, i) => (
              <motion.div key={story.title} animate={{ scale: i === currentSlide ? 1 : 0.9 }}>
                <CampaignPageImpactStoryCard
                  buttonAction={section?.config.buttonAction}
                  onDonate={onDonate}
                  onJoin={onJoin}
                  {...story}
                />
              </motion.div>
            ))}
          </SwipeableViews>
        ) : (
          <div className="flex flex-wrap justify-center -ml-4 -mt-4">
            {section?.config.stories.map((story, i) => (
              <div key={`story-${i}`} className="pl-4 pt-4 w-1/3 xl:w-1/4">
                <CampaignPageImpactStoryCard
                  className="h-full"
                  buttonAction={section?.config.buttonAction}
                  onDonate={onDonate}
                  onJoin={onJoin}
                  {...story}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

CampaignPageImpactStories.propTypes = {
  id: PropTypes.string.isRequired,
  onDonate: PropTypes.func,
  onJoin: PropTypes.func,
  onDonate: PropTypes.func,
  className: PropTypes.string,
};

CampaignPageImpactStories.defaultProps = {
  onDonate: () => {},
  onJoin: () => {},
  className: '',
};

export default CampaignPageImpactStories;
