import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';

import { useCampaignPage } from '@/context/CampaignPage';
import cx from '@/lib/cx';
import { gql } from '@/generated';

import ShopCard from './CampaignPageShopCard';

const GET_SHOP_WIDGET_DETAILS = gql(/* GraphQL */ `
  query GetShopWidgetDetails($campaignId: String!, $widgetId: String!, $isExisting: Boolean!) {
    findCampaigns(id: $campaignId) {
      id
      activeFeatures {
        shop
      }
      products(order: "sort", where: { isEnabled: true }) {
        id
        name
        description
        amount
        image
        qtyRemaining
        variants {
          id
          qtyRemaining
        }
      }
      widgets(where: { id: $widgetId }) @include(if: $isExisting) {
        id
        title
      }
    }
  }
`);

type CampaignPageShopProps = {
  id: string;
  className?: string;
};

const CampaignPageShop = ({ id, className }: CampaignPageShopProps) => {
  const router = useRouter();

  const { campaignId } = useCampaignPage();
  const { data } = useQuery(GET_SHOP_WIDGET_DETAILS, {
    variables: { campaignId, widgetId: id, isExisting: !!id },
  });

  const campaign = data?.findCampaigns?.[0];
  const isShopFeatureActive = campaign?.activeFeatures.shop;
  const products = campaign?.products || [];
  const shopWidget = campaign?.widgets?.[0];
  const count = products.length;

  if (!shopWidget) return null;
  return (
    <div className={cx('py-16 bg-white', className)}>
      <div className="container max-w-7xl">
        <h2 className={'text-2xl lg:text-4xl text-center font-medium leading-tight'}>
          {shopWidget.title}
        </h2>

        <section
          className={cx(`grid mt-10 gap-8`, {
            'sm:grid-cols-2 sm:gap-10': count > 1,
            'md:grid-cols-3 md:gap-6': count === 3,
            'lg:grid-cols-4 md:gap-6': count > 3,
          })}
        >
          {products.map((item) => (
            <ShopCard
              key={item.id}
              {...item}
              amount={Number(item.amount)}
              clickHandler={() =>
                router.push(`/c/${campaignId}/cart?category=shop&product=${item.id}`)
              }
              isSmall={count > 3}
              isSingleProduct={count === 1}
              isDescriptionRequired={count <= 2}
              isShopFeatureActive={isShopFeatureActive}
            />
          ))}
        </section>
      </div>
    </div>
  );
};

export default CampaignPageShop;
