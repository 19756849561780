import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faClock, faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import Link from 'components/common/Link';
import { formatDateRange } from 'lib/formatters';
import { faCircleLocationArrow } from '@fortawesome/pro-regular-svg-icons';

const CampaignPageEventAddress = ({ eventDetails }) => {
  const isAddressAvailable =
    eventDetails.address1 || eventDetails.city || eventDetails.stateProv || eventDetails.country;

  const fullAddress = [
    eventDetails.address1,
    eventDetails.address2,
    eventDetails.city,
    eventDetails.stateProv,
    eventDetails.country,
    eventDetails.postalCode,
  ]
    .filter((address) => address)
    .join(', ');

  let mapAddress = fullAddress;
  if (eventDetails.location) mapAddress = eventDetails.location + ', ' + fullAddress;

  return (
    <div className="w-full border border-gray-400 p-6 rounded-lg grid gap-4">
      <div className="flex items-center gap-3">
        <FontAwesomeIcon icon={faCalendar} />
        <p>{formatDateRange(eventDetails.startDate, eventDetails.endDate)}</p>
      </div>

      {eventDetails.time && (
        <div className="flex items-center gap-3">
          <FontAwesomeIcon icon={faClock} />
          <p>{eventDetails.time}</p>
        </div>
      )}

      {isAddressAvailable && (
        <>
          <div className="flex items-start gap-3">
            <FontAwesomeIcon icon={faLocationDot} />
            <div className="-mt-1">
              {eventDetails.location && <p>{eventDetails.location}</p>}
              <p>{fullAddress}</p>
            </div>
          </div>

          <Link
            href={`http://maps.google.com/?q=${mapAddress.split(' ').join('%20')}&output=classic`}
            className="mt-2 bg-theme-primary text-sm md:text-base text-white font-medium rounded-lg p-3 flex items-center justify-center gap-2"
          >
            <FontAwesomeIcon icon={faCircleLocationArrow} className="w-4 h-4" />
            <p>Get Directions</p>
          </Link>
        </>
      )}
    </div>
  );
};

CampaignPageEventAddress.propTypes = {
  eventDetails: PropTypes.object.isRequired,
};

CampaignPageEventAddress.defaultProps = {
  eventDetails: {},
};

export default CampaignPageEventAddress;
